import { getCountryCode } from '../localization/utils';

export const getLocationOriginSSR = () => {
  if (typeof location !== 'undefined') {
    return location.origin;
  }

  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }

  const urlEnvironment = process.env.clikalia.;
  const countryCode = getCountryCode();
  return `https://${urlEnvironment}${countryCode.toLowerCase()}`;
};

export const getLocationHref = () => (typeof location !== 'undefined' ? location.href : '');
