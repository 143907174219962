import { AvailableCountries } from 'cross-config/localization/config';

type DynamicComponentsByCountry<T> = Record<AvailableCountries, T>;

const useDynamicContent = <T,>(
  components: DynamicComponentsByCountry<T>,
  country: AvailableCountries
): T => {
  return components[country];
};

export default useDynamicContent;
