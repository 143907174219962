import React from 'react';
import Head from 'next/head';
import { HeadSEOProps } from './HeadSEO.types';

const HeadSEO = ({ title, description }: HeadSEOProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content={title} property="og:title" />
      <meta name="description" content={description} />
      {process.env.NODE_ENV !== 'production' && <meta content="noindex, nofollow" name="robots" />}
      <link href="/favicon-square.ico" rel="icon" sizes="16x16" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="icon" sizes="32x32" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="icon" sizes="96x96" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="icon" sizes="192x192" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="57x57" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="60x60" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="72x72" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="76x76" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="114x114" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="120x120" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="144x144" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="152x152" type="image/x-icon" />
      <link href="/favicon-square.ico" rel="apple-touch-icon" sizes="180x180" type="image/x-icon" />
    </Head>
  );
};

export default HeadSEO;
