import { useContext } from 'react';
import { FlagsmithContext } from '../../../../clikalia-web-components/src/contexts/FlagsmithContext';

const useFlagByName = (featureName: string) => {
  const context = useContext(FlagsmithContext);

  if (!context) {
    throw new Error('useFlagByName must be used within a FlagsmithProvider');
  }

  const flags = context.flagSmithConf;

  return flags?.find((flag) => flag.feature.name === featureName && flag.enabled);
};

export default useFlagByName;
