import { useMemo } from 'react';
import { HeaderStyled } from './Header.styled';
import { HeaderProps } from './Header.types';
import { HeaderContext } from './Header.context';
import HeaderRightContent from './components/HeaderRightContent';
import HeaderLeftContent from './components/HeaderLeftContent';

const Header: React.FC<HeaderProps> & {
  LeftContent: typeof HeaderLeftContent;
  RightContent: typeof HeaderRightContent;
} = ({
  children,
  position = 'sticky',
  transparent = false,
  testId = 'headerComponent',
  backgroundColor,
  newDesign,
  className = '',
  ...rest
}) => {
  const contextValue = useMemo(() => ({ transparent, newDesign }), [transparent, newDesign]);

  const headerClasses = `${className} ${newDesign ? 'new-design' : ''}`.trim();

  return (
    <HeaderStyled
      id={testId}
      data-testid={testId}
      position={position}
      transparent={transparent}
      backgroundColor={backgroundColor}
      className={headerClasses}
      {...rest}
    >
      <HeaderContext.Provider value={contextValue}>{children}</HeaderContext.Provider>
    </HeaderStyled>
  );
};

Header.LeftContent = HeaderLeftContent;

Header.RightContent = HeaderRightContent;

export default Header;
