import { useState, useEffect, useCallback } from 'react';
import { LocationFromServer, SiteConfig } from 'cross-config/generic/types';
import { AvailableCountries } from 'cross-config/localization/config';

interface useZonesProps {
  zones: SiteConfig['zones'];
  location: LocationFromServer;
  countryCode: AvailableCountries;
}

type Zone = useZonesProps['zones'][0];

const defaultZone: Zone = {
  address: '',
  name: '',
  phone: '',
  phonePrivateArea: '',
  url: '',
};

const countryCapitals = {
  ES: 'Madrid',
  FR: 'Paris',
  PT: 'Lisboa',
};

const useZones = ({ zones, location, countryCode }: useZonesProps) => {
  const getZone = useCallback(() => {
    const selectedCityZone = zones.find((zone) => zone.name === location.city);

    if (selectedCityZone) {
      return selectedCityZone;
    }
    return zones.find((zone) => zone.name === countryCapitals[countryCode]) || defaultZone;
  }, [zones, location, countryCode]);

  const [zone, setZone] = useState<Zone>(getZone());

  useEffect(() => {
    setZone(getZone());
  }, [getZone]);

  return zone;
};

export default useZones;
