import { Icon, TypographyOld as Typography, defaultTheme } from '@clikaliatech/clikalia-ui';
import BurgerMenu from '../../../BurgerMenu';
import {
  BurguerContactStyled,
  FlagStyled,
  HeaderLeftWrapperStyled,
} from './HeaderLeftContent.styled';
import { HeaderLeftContentProps } from './HeaderLeftContent.types';
import { useCallback, useContext } from 'react';
import { HeaderContext } from '../../Header.context';

const countryInfo: Record<string, { label: string; flag: string }> = {
  es: {
    label: 'Clikalia España',
    flag: '🇪🇸',
  },
  mx: {
    label: 'Clikalia Mexico',
    flag: '🇲🇽',
  },
  pt: {
    label: 'Clikalia Portugal',
    flag: '🇵🇹',
  },
  fr: {
    label: 'Clikalia Francia',
    flag: '🇫🇷',
  },
};

const HeaderLeftContent: React.FC<HeaderLeftContentProps> = ({
  menuOptions,
  contactOptions,
  onClick,
  children,
}) => {
  const { transparent, newDesign } = useContext(HeaderContext);

  const getContactComponents = useCallback(
    () => [
      {
        option: (
          <BurguerContactStyled href={`tel:${contactOptions?.phone}`} id="burger-menu-phone">
            <Icon color={Icon.Colors.WHITE} variant={Icon.Variants.REGULAR} name="phone-flip" />
            <Typography type={Typography.Types.B1Medium} color={defaultTheme.palette.white}>
              {contactOptions?.phone}
            </Typography>
          </BurguerContactStyled>
        ),
      },
      {
        option: (
          <BurguerContactStyled href={`mailto:${contactOptions?.email}`} id="burger-menu-email">
            <Icon color={Icon.Colors.WHITE} variant={Icon.Variants.REGULAR} name="email" />
            <Typography type={Typography.Types.B1Medium} color={defaultTheme.palette.white}>
              {contactOptions?.email}
            </Typography>
          </BurguerContactStyled>
        ),
      },
      {
        option: (
          <BurguerContactStyled>
            <FlagStyled>{countryInfo[contactOptions?.country || 'es'].flag}</FlagStyled>
            <Typography type={Typography.Types.B1Medium} color={defaultTheme.palette.white}>
              {countryInfo[contactOptions?.country || 'es'].label}
            </Typography>
          </BurguerContactStyled>
        ),
      },
    ],
    [contactOptions]
  );

  return (
    <HeaderLeftWrapperStyled>
      {menuOptions && contactOptions && (
        <BurgerMenu
          color={transparent && !newDesign ? Icon.Colors.WHITE : Icon.Colors.BLACK}
          menuOptions={menuOptions}
          contactOptions={getContactComponents()}
          onClick={onClick}
        />
      )}
      {children}
    </HeaderLeftWrapperStyled>
  );
};

export default HeaderLeftContent;
