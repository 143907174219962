/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

type UseRequestProps<T extends (...args: any[]) => Promise<any>> = {
  onSuccess?: (response: Awaited<ReturnType<T>>) => void;
  onError?: (error: unknown) => void;
};

type UseRequestState<T extends (...args: any[]) => Promise<any>> = {
  data?: Awaited<ReturnType<T>>;
  loading: boolean;
};

const useRequest = <T extends (...args: any[]) => Promise<any>>(
  fetcher: T,
  { onSuccess, onError }: UseRequestProps<T> = {}
) => {
  const [state, setState] = useState<UseRequestState<T>>({ loading: false });

  const request = useCallback(
    async (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
      setState((current) => ({ ...current, loading: true }));

      try {
        const fetcherResponse = await fetcher(...args);
        setState({ data: fetcherResponse, loading: false });
        onSuccess?.(fetcherResponse);

        return fetcherResponse;
      } catch (error) {
        setState((current) => ({ ...current, loading: false }));
        onError?.(error);

        throw error;
      }
    },

    [fetcher, onSuccess, onError]
  );

  return { request, loading: state.loading, data: state.data };
};

export default useRequest;
