import styled from 'styled-components';
import { ListItemProps } from './HeaderLangugage.types';
import { DropdownGeneric } from '@clikaliatech/clikalia-ui';

export const LanguageButtonStyled = styled.button(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  marginTop: theme.spacing(0.5),
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(1),
  },
}));

export const DropdownLanguage = styled(DropdownGeneric<string>)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  padding: `${theme.spacing(3)} !important`,
  backgroundColor: theme.palette.black,
  borderRadius: 4,
  right: 0,
  minWidth: '105px !important',
  zIndex: `${theme.zIndex.modal} !important`,

  li: {
    margin: '0 !important',
  },
}));

export const LanguageItem = styled.div<ListItemProps>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1.5),
  cursor: 'pointer',
  maxHeight: '35px',
  opacity: 0.5,
  transition: 'all 0.2s ease',

  ...(active && {
    opacity: 1,
  }),

  ':hover': {
    opacity: 1,
    p: {
      fontSize: theme.typography.b1Bold.fontSize,
      fontWeight: theme.typography.b1Bold.fontWeight,
      lineHeight: theme.typography.b1Bold.lineHeight,
      letterSpacing: theme.typography.b1Bold.letterSpacing,
      color: theme.palette.white,
    },
  },
}));

export const FlagStyled = styled.span(({ theme }) => ({
  fontSize: theme.size(2),
  fontFamily: `'TwemojiCountryFlags'`,
  display: 'inline-block',
  verticalAlign: 'text-bottom',
}));
