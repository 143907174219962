import Cookie from 'js-cookie';

type SetCookieProps = {
  key: string;
  value: string;
  opts?: Cookies.CookieAttributes;
};

type RemoveCookieProps = {
  key: string;
  opts?: Cookies.CookieAttributes;
};

const getDomain = () => {
  const { hostname } = window.location;
  if (hostname === 'localhost') return hostname;
  return `.${hostname.split('.').slice(-2).join('.')}`;
};

const useCookie = () => {
  const getCookie = (key: string) => {
    return Cookie.get(key);
  };

  const setCookie = ({ key, value, opts }: SetCookieProps) => {
    Cookie.set(key, value, {
      domain: getDomain(),
      ...opts,
    });
  };

  const removeCookie = ({ key, opts }: RemoveCookieProps) => {
    Cookie.remove(key, { domain: getDomain(), ...opts });
  };

  return { getCookie, setCookie, removeCookie };
};

export default useCookie;
