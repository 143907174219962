import styled from 'styled-components';

export const HeaderLogoButtonStyled = styled.button(({ theme }) => ({
  cursor: 'pointer',
  border: 'none',
  background: 'none',
  padding: 0,
  svg: {
    width: '73px',
    height: '19px',
    [theme.breakpoints.up('sm')]: {
      width: '108px',
      height: '28px',
    },
  },
}));
