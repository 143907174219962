import { forwardRef, useContext, useState, useCallback, useEffect } from 'react';
import {
  DropdownGeneric,
  Icon,
  TypographyOld as Typography,
  defaultTheme,
} from '@clikaliatech/clikalia-ui';
import { HeaderContext } from '../../Header.context';
import {
  LanguageItem,
  LanguageButtonStyled,
  DropdownLanguage,
  FlagStyled,
} from './HeaderLanguage.styled';
import { HeaderLanguageProps, LanguageButtonProps } from './HeaderLangugage.types';
import { languagesWithFlags } from 'cross-config/localization/config';
import CataloniaFlag from '../../../../../statics/images/catalonia-flag.svg';

const cataloniaFlag = CataloniaFlag.src || CataloniaFlag;

const IsoLanguageToSVGComponent: Record<string, unknown> = {
  'ca-ES': cataloniaFlag,
};

const LanguageButton = forwardRef<HTMLButtonElement, LanguageButtonProps>(
  ({ label, isOpen, ...props }, ref) => {
    const { transparent, newDesign } = useContext(HeaderContext);

    return (
      <LanguageButtonStyled ref={ref} {...props}>
        <Typography
          type={Typography.Types.B1Regular}
          color={
            transparent && !newDesign ? defaultTheme.palette.white : defaultTheme.palette.black
          }
        >
          {label}
        </Typography>
        <Icon
          color={transparent && !newDesign ? Icon.Colors.WHITE : Icon.Colors.BLACK}
          variant={Icon.Variants.REGULAR}
          name={isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </LanguageButtonStyled>
    );
  }
);

LanguageButton.displayName = 'languageButtonComponent';

const HeaderLanguage: React.FC<HeaderLanguageProps> = ({ language, country = 'ES', onSelect }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>();

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(undefined);
    }
  }, [isOpen]);

  const handleOnSelect = useCallback(
    (value: string) => {
      onSelect(value);
      setIsOpen(false);
    },
    [onSelect]
  );
  const languages = languagesWithFlags[country];

  const handleOnChangeActive = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const handleOnMouseEnterItem = useCallback(() => {
    setActiveIndex(undefined);
  }, []);

  return (
    <DropdownLanguage
      testId="languageDropdownComponent"
      open={isOpen}
      onOpenChange={setIsOpen}
      onSelect={handleOnSelect}
      onChangeActive={handleOnChangeActive}
      openNode={<LanguageButton label={language} isOpen={isOpen} />}
    >
      {languages.map(({ label, value, flag, svg }, index) => {
        const isActive = activeIndex === index || language === label;
        const { B1Bold, B1Regular } = Typography.Types;
        const { white, darkGray30 } = defaultTheme.palette;

        return (
          <DropdownGeneric.Item
            key={JSON.stringify(label)}
            value={value}
            data-testid={`${label}-dropdownItem`}
            onMouseEnter={handleOnMouseEnterItem}
          >
            <LanguageItem active={isActive}>
              <FlagStyled>
                {svg ? (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    alt="country-flag"
                    height={22}
                    width={20}
                    src={IsoLanguageToSVGComponent[value] as string}
                  />
                ) : (
                  flag
                )}
              </FlagStyled>
              <Typography
                type={isActive ? B1Bold : B1Regular}
                color={isActive ? white : darkGray30}
              >
                {label}
              </Typography>
            </LanguageItem>
          </DropdownGeneric.Item>
        );
      })}
    </DropdownLanguage>
  );
};

export default HeaderLanguage;
