import { getLocationOriginSSR } from './getLocationOriginSSR';

export const handleAnchorEvents = (e: React.MouseEvent<HTMLElement>) => {
  if ((e.target as HTMLElement).tagName === 'A') {
    e.preventDefault();
    const href = (e.target as HTMLAnchorElement).href;
    if (href.startsWith(window.location.origin)) {
      window.location.href = href;
    } else {
      window.open(href, '_blank');
    }
  }
};

interface ConvertStrapiURLWithLocaleProps {
  url: string;
  locale?: string;
  defaultLocale?: string;
}

export const convertStrapiURLWithLocale = ({
  url,
  locale,
  defaultLocale,
}: ConvertStrapiURLWithLocaleProps): string => {
  if (!url.startsWith('http')) {
    const localeToReplace = locale === defaultLocale ? '' : locale;
    url = url.replace('/:locale', localeToReplace ? `/${localeToReplace}` : '');
    return `${getLocationOriginSSR()}${url}`;
  }

  return url;
};
