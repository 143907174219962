import { defaultTheme, Drawer, Icon } from '@clikaliatech/clikalia-ui';
import {
  BurgerIcon,
  BurgerContent,
  ButtonCloseStyled,
  ListContactElement,
  ListContactWrapper,
  ListElementStyled,
  ListStyled,
  BurgerButton,
} from './BurgerMenu.styled';
import { BurgerMenuThemes, BurgerMenuWithExtendedOptions } from './BurgerMenu.types';
import { useEffect, useState } from 'react';

const BurgerMenu: BurgerMenuWithExtendedOptions = ({
  menuOptions,
  contactOptions,
  color,
  onClick,
  theme = BurgerMenuThemes.DARK,
  testId = 'burgerMenuComponent',
}) => {
  // Used this state for prevents hydration issues when phone is according user zone.
  // In this case, the phone may differ in server and client.
  const [showContactOptions, setShowContactOptions] = useState(false);

  useEffect(() => {
    setShowContactOptions(true);
  }, []);

  const isDarkTheme = theme === BurgerMenuThemes.DARK;

  return (
    <Drawer backgroundColor={isDarkTheme ? defaultTheme.palette.black : 'white'} anchor={'left'}>
      <Drawer.Open>
        <BurgerButton aria-label="burger-menu-button" data-testid={`${testId}-drawer-open-btn`}>
          <BurgerIcon name="bars" variant={Icon.Variants.REGULAR} color={color} testId={testId} />
        </BurgerButton>
      </Drawer.Open>
      <Drawer.Content>
        <BurgerContent data-testid={`${testId}-content`}>
          <Drawer.Close>
            <ButtonCloseStyled>
              <Icon
                name="xmark"
                variant={Icon.Variants.REGULAR}
                color={isDarkTheme ? Icon.Colors.WHITE : Icon.Colors.BLACK}
              />
            </ButtonCloseStyled>
          </Drawer.Close>
          <ListStyled onClick={onClick}>
            {menuOptions?.map(({ label, active, url }) => (
              <ListElementStyled
                darkTheme={isDarkTheme}
                key={label}
                className={active ? 'active' : ''}
              >
                <a
                  href={url}
                  data-testid={`${testId}-link-to-${url.replace(/\/|:|https?/g, '')}`}
                  id={`burger-menu-${label.toLowerCase().split(' ').join('-')}`}
                >
                  {label}
                </a>
              </ListElementStyled>
            ))}
          </ListStyled>
          {showContactOptions && (
            <ListContactWrapper>
              <ListStyled>
                {contactOptions.map(({ option }, index) => (
                  <ListContactElement key={index} darkTheme={isDarkTheme}>
                    {option}
                  </ListContactElement>
                ))}
              </ListStyled>
            </ListContactWrapper>
          )}
        </BurgerContent>
      </Drawer.Content>
    </Drawer>
  );
};

BurgerMenu.Theme = BurgerMenuThemes;

export default BurgerMenu;
