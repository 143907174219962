import styled from 'styled-components';

import { HeaderStyledProps } from './Header.types';

const HeaderStyled = styled.header<HeaderStyledProps>(
  ({ theme, position, transparent, backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    position,
    top: 0,
    width: '100%',
    height: '66px',
    zIndex: theme.zIndex.navBar,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1.75),
    backgroundColor: backgroundColor || (transparent ? 'transparent' : theme.palette.white),
    transition: '0.4s ease background-color',

    [theme.breakpoints.up('sm')]: {
      height: '70px',
    },

    '&.new-design': {
      height: '68px',
      [theme.breakpoints.up('sm')]: {
        height: '84px',
      },
    },
  })
);

export { HeaderStyled };
