import styled from 'styled-components';
import { Icon } from '@clikaliatech/clikalia-ui';

const BurgerButton = styled.button(({ theme }) => ({
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  fontSize: theme.size(2),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0.5),
  },

  span: {
    display: 'flex',
  },
}));

const BurgerIcon = styled(Icon)({
  zIndex: 1,
});

const BurgerContent = styled.div(({ theme: { spacing } }) => ({
  padding: spacing(7, 0),
  width: '300px',
}));

const ListContactWrapper = styled.div(({ theme: { spacing } }) => ({
  paddingTop: spacing(5),
}));
const ListStyled = styled.ul(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2.5, 0),
  listStyleType: 'none',
}));

const ListContactElement = styled.li<{ darkTheme?: boolean }>(
  ({ darkTheme, theme: { spacing, palette, typography } }) => ({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    listStyleType: 'none',
    padding: spacing(0, 0.5),
    '*': {
      color: darkTheme ? palette.white : palette.black,
      fontSize: typography.b1Medium.fontSize,
      fontWeight: typography.b1Medium.fontWeight,
      letterSpacing: typography.b1Medium.letterSpacing,
      lineHeight: typography.b1Medium.lineHeight,
    },
  })
);

const ListElementStyled = styled.li<{ darkTheme?: boolean }>(
  ({ darkTheme, theme: { palette, typography } }) => {
    const { b1Bold, b1Regular } = typography;

    return {
      a: {
        color: darkTheme ? palette.darkGray30 : palette.black30,
        fontSize: b1Regular.fontSize,
        fontWeight: b1Regular.fontWeight,
        letterSpacing: b1Regular.letterSpacing,
        lineHeight: b1Regular.lineHeight,
        textDecoration: 'none',
        transition: 'all 0.2s ease',
      },
      'a:hover, &.active a': {
        color: darkTheme ? palette.white : palette.black,
        fontSize: b1Bold.fontSize,
        fontWeight: b1Bold.fontWeight,
        letterSpacing: b1Bold.letterSpacing,
        lineHeight: b1Bold.lineHeight,
      },
    };
  }
);

const ButtonCloseStyled = styled.button(({ theme: { palette, size } }) => ({
  backgroundColor: palette.transparent,
  border: 'none',
  cursor: 'pointer',
  fontSize: size(2),
  position: 'absolute',
  right: '16px',
  top: '16px',
}));

export {
  BurgerButton,
  BurgerIcon,
  BurgerContent,
  ListStyled,
  ListElementStyled,
  ListContactElement,
  ListContactWrapper,
  ButtonCloseStyled,
};
