import { LogoProps } from './ClikaliaLogo.types';

const ClikaliaLogo: React.FC<LogoProps> = ({
  color = '#363232',
  width = '108',
  height = '28',
  houseColor = '#FF0049',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1.18206 14.2248C1.96951 12.8776 3.043 11.8285 4.39907 11.0808C5.75514 10.3331 7.25596 9.95923 8.90151 9.95923C11.1967 9.95923 12.8956 10.5537 14.3448 11.7426C15.7922 12.9315 16.7037 14.4892 17.0741 16.4157H13.4246C13.4332 16.052 12.8577 13.2212 8.97043 13.2212C7.48685 13.2212 6.25829 13.7314 5.28474 14.7502C4.31119 15.7691 3.82528 17.4547 3.82528 19.1303C3.82528 20.8295 4.31291 22.1885 5.28474 23.2073C6.25829 24.2261 7.48685 24.7364 8.97043 24.7364C10.3386 24.7364 13.2661 23.7799 13.4246 21.5755H17.212C16.7243 23.502 15.7629 25.053 14.3258 26.2301C12.8887 27.4089 11.207 27.9966 8.93425 27.9966C7.28869 27.9966 5.78099 27.6177 4.41458 26.8582C3.04644 26.0988 1.96951 25.0463 1.18033 23.6974C0.391156 22.3502 -0.00170898 20.8261 -0.00170898 19.127C-0.00170898 17.4278 0.394602 15.572 1.18206 14.2248Z"
        fill={color}
      />
      <path d="M24.0405 27.5251H20.2515V3.73853H24.0405V27.5251Z" fill={color} />
      <path d="M32.1409 27.5251H28.3518V10.7726H32.1409V27.5251Z" fill={color} />
      <path
        d="M53.8364 10.7726L45.7706 17.5002L53.6986 27.5251H49.0049L43.024 19.777L40.2429 22.1212V27.5235H36.4539V3.73853H40.2429V17.5676L48.1709 10.7709H53.8364V10.7726Z"
        fill={color}
      />
      <path d="M79.176 27.5251H75.387V3.73853H79.176V27.5251Z" fill={color} />
      <path
        d="M83.6681 4.14435C84.1161 3.72503 84.683 3.51453 85.3654 3.51453C86.0477 3.51453 86.6146 3.72503 87.0626 4.14435C87.5106 4.56366 87.7363 5.1076 87.7363 5.77446C87.7363 6.42281 87.5123 6.95664 87.0626 7.37596C86.6129 7.79527 86.0477 8.00577 85.3654 8.00577C84.6813 8.00577 84.1161 7.79696 83.6681 7.37596C83.2184 6.95664 82.9944 6.42281 82.9944 5.77446C82.9944 5.1076 83.2184 4.56366 83.6681 4.14435ZM87.278 27.5251H83.4889V10.7726H87.278V27.5251Z"
        fill={color}
      />
      <path
        d="M30.1954 8.50761L25.8894 3.74692L29.3632 3.72334L30.2195 4.77248L33.6795 0H36.7828L30.1954 8.50761Z"
        fill={houseColor}
      />
      <path
        d="M104.115 10.7726V11.7746C102.703 10.6783 101.049 9.95923 98.8454 9.95923C97.1998 9.95923 95.699 10.3331 94.3429 11.0808C92.9868 11.8285 91.9151 12.8759 91.1259 14.2248C90.3367 15.572 89.9438 17.4329 89.9438 19.132C89.9438 20.8312 90.3384 22.3552 91.1259 23.7024C91.9133 25.0496 92.992 26.1038 94.3601 26.8633C95.7283 27.6211 97.2343 28.0017 98.8798 28.0017C100.462 28.0017 101.757 27.7154 102.884 27.1445V22.889C101.962 24.1672 99.9722 24.7398 98.916 24.7398C97.4324 24.7398 96.2038 24.2295 95.2303 23.2107C94.2567 22.1919 93.7708 20.8329 93.7708 19.1337C93.7708 17.4581 94.2585 15.7724 95.2303 14.7536C96.2038 13.7348 97.4324 13.2245 98.916 13.2245C102.805 13.2245 104.115 16.2086 104.115 17.1954V18.706V27.5268H107.828V10.7726H104.115Z"
        fill={color}
      />
      <path
        d="M72.3319 27.5082H68.9116V19.6439L63.4907 14.4909L57.7546 19.3509V22.6718L59.0486 24.3373L67.5107 24.504V27.5082H57.5633L54.3359 23.4262V17.837L63.5717 10.1461L72.3319 18.199V27.5082Z"
        fill={houseColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1033_20722">
        <rect width="108" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ClikaliaLogo;
