import { Icon } from '@clikaliatech/clikalia-ui';
import { ComponentProps } from 'react';

export type MenuOptions = {
  url: string;
  label: string;
  active?: boolean;
};

export type ContactOptions = {
  option: React.ReactNode;
};

export enum BurgerMenuThemes {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export type BurgerMenuProps = Pick<ComponentProps<typeof Icon>, 'color'> & {
  menuOptions: MenuOptions[] | undefined;
  contactOptions: ContactOptions[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  theme?: BurgerMenuThemes;
  testId?: string;
};

export interface BurgerMenuWithExtendedOptions extends React.FC<BurgerMenuProps> {
  Theme: typeof BurgerMenuThemes;
}
