import { RefObject, useEffect } from 'react';

type OptionsProps = {
  disabled?: boolean;
};

function useClickOutside(
  ref: RefObject<HTMLDivElement>,
  handler: (event: MouseEvent) => void,
  options?: OptionsProps
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const elem = ref.current;

      if (!options?.disabled && elem && !elem?.contains(event.target as Node)) {
        handler(event);
      }
    }

    if (!options?.disabled) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, options?.disabled]);
}

export default useClickOutside;
