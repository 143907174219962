import styled from 'styled-components';

export const HeaderLeftWrapperStyled = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const BurguerContactStyled = styled.a(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  textDecoration: 'none',
  p: {
    margin: 0,
  },
}));

export const FlagStyled = styled.span(({ theme }) => ({
  fontSize: theme.size(2),
  fontFamily: `'TwemojiCountryFlags'`,
  display: 'inline-block',
  verticalAlign: 'text-bottom',
}));
