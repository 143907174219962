import { useContext } from 'react';
import { HeaderContext } from '../../Header.context';
import { HeaderLogoButtonStyled } from './HeaderLogo.styled';
import { HeaderLogoProps } from './HeaderLogo.types';
import ClikaliaLogo from '../../../../atoms/ClikaliaLogo';

const HeaderLogo: React.FC<HeaderLogoProps> = ({ pathname }) => {
  const { transparent, newDesign } = useContext(HeaderContext);

  const handleOnClick = () => {
    window.location.href = `${location.origin}${pathname}`;
  };
  return (
    <HeaderLogoButtonStyled
      aria-label="Logo button"
      data-testid="logoComponent"
      onClick={handleOnClick}
    >
      {transparent && !newDesign ? <ClikaliaLogo color="white" /> : <ClikaliaLogo />}
    </HeaderLogoButtonStyled>
  );
};

export default HeaderLogo;
