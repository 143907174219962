import styled from 'styled-components';
import { HeaderRightContentProps } from './HeaderRightContent.types';

const HeaderRightContentStyled = styled.div<HeaderRightContentProps>({
  flex: '1 0 auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export { HeaderRightContentStyled };
